import styled from 'styled-components';

export const Container = styled.div`
  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .payment_btn {
    margin-top: 20px;
  }

  .content {
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .horizontal_1 {
    display: flex;
    margin-top: 150px;
    width: 55%;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }

  .horizontal_2 {
    display: flex;
    margin-top: 150px;
    width: 55%;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }
  .spacePrice1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .h3_TitlePrice {
    font-size: 20px;
    color: #7e1280;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .h3_Price {
    font-size: 30px;
    color: #f2a71b;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 30px;

    img {
      width: 220px;
      height: 35px;
      margin-top: -5px;
    }
  }

  /* Responsive */
  /*  @media (max-width: 1800px) {
    .h3_Price {
      font-size: 28px;
    }
    .h3_TitlePrice {
      font-size: 20px;
    }
  }

  @media (max-width: 1660px) {
    .content {
      gap: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .horizontal_2,
    .horizontal_1 {
      justify-content: center;
      margin-top: 0px;
    }

    .h3_Price {
      font-size: 26px;
    }
    .h3_TitlePrice {
      font-size: 18px;
    }
  }

  @media (max-width: 1500px) {
    .content {
      gap: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .h3_Price {
      font-size: 24px;
    }
    .h3_TitlePrice {
      font-size: 17px;
    }
  }

  @media (max-width: 1400px) {
    .content {
      gap: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .h3_Price {
      font-size: 23px;
    }
    .h3_TitlePrice {
      font-size: 16px;
    }
  }

  @media (max-width: 900px) {
    .content {
      gap: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .h3_Price {
      font-size: 22px;
    }
    .h3_TitlePrice {
      font-size: 15px;
    }
  }

  @media (max-width: 700px) {
    .h3_Price {
      font-size: 21px;
    }
    .h3_TitlePrice {
      font-size: 14px;
    }
  }

  @media (max-width: 500px) {
    .h3_Price {
      font-size: 20px;
    }
    .h3_TitlePrice {
      font-size: 13px;
    }
  } */
  /* End of Responsive */
`;
