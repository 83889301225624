import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.css';

import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import GlobalStyle from './styles/global';
import Routes from './routes';
import AppProvider from './hooks';

const App: React.FC = () => {
  return (
    <ConfigProvider locale={ptBR}>
      <GlobalStyle />
      <BrowserRouter>
        <AppProvider>
          <Routes />
        </AppProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
