import styled from 'styled-components';

interface IAreaSelected {
  color: string;
}

export const Container = styled.div`
  height: 100%;

  .info-area {
    display: flex;

    align-items: center;

    justify-content: space-between;

    .title {
      display: flex;
      gap: 10px;
    }
  }

  #confirm-area {
    display: flex;
    align-items: center;
  }
`;

export const QuadrantArea = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;

  > div {
    margin-right: 50px;
    align-self: flex-start;
  }

  & + div {
    margin-top: 10px;
  }

  .quadrant-card {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    height: 66px;

    border-radius: 5px;

    font-family: 'Baloo Bhai 2';
    font-weight: 500;

    -webkit-box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.67);
    -moz-box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.67);
    box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.67);
  }

  .life-area-card {
    position: relative;
    border-radius: 5px;
  }
`;

export const AreaSelected = styled.div<IAreaSelected>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29px;
  height: 29px;
  background-color: ${props => props.color};
  box-shadow: 1px 1px 3px gray;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;

  position: absolute;

  top: -5px;
  right: -5px;
`;
