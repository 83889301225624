import { lighten } from '@material-ui/core';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background-color: ${lighten('#6b0a95', 0.6)};
  }

  body, input, button {
    font-family: 'Baloo Bhai 2', sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
    margin: 0;
    color: #404040;
  }

  font {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    margin-bottom: 10px;
    width: 100%;
  }

  .inputs-group {
    display: flex;
    gap: 16px;

    @media (max-width: 786px) {
      flex-direction: column;
    }
  }
`;
