import { createContext, useCallback, useContext, useState } from 'react';

interface SideMenuContextData {
  isDrawerOpen: boolean;
  handleToggleDrawer(): void;
}

const SideMenuContext = createContext<SideMenuContextData>(
  {} as SideMenuContextData,
);

export const SideMenuProvider: React.FC = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const handleToggleDrawer = useCallback(() => {
    setIsDrawerOpen(prevState => !prevState);
  }, []);

  return (
    <SideMenuContext.Provider value={{ isDrawerOpen, handleToggleDrawer }}>
      {children}
    </SideMenuContext.Provider>
  );
};

export function useSideMenu(): SideMenuContextData {
  const context = useContext(SideMenuContext);

  if (!context) {
    throw new Error('useSideMenu must be used within a SideMenuProvider');
  }

  return context;
}
