import { Button, Card, Input, message, Modal, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { MdUpdate } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import { Container, QuadrantArea } from './styles';

interface IQuadrant {
  _id: string;
  name: string;
  color: string;
  description?: string;
  lifeAreas: string[];
}

interface ILifeArea {
  _id: string;
  name: string;
  color: string;
  description: string;
  initial?: number;
  desired?: number;
  current?: number;
}

interface ILifeAreaState {
  lifeArea: string;
  initial?: number;
  desired?: number;
  current?: number;
}

interface IAreaToBalance {
  lifeArea: string;
  position?: number;
}

const LifeEvolution: React.FC = () => {
  const [quadrants, setQuadrants] = useState<IQuadrant[]>([]);
  const [lifeAreas, setLifeAreas] = useState<ILifeArea[]>([]);
  const [lifeAreasStates, setLifeAreasStates] = useState<ILifeAreaState[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [didComplete, setDidComplete] = useState(false);
  const [currentDescription, setCurrentDescription] = useState('');
  const [areasToBalance, setAreasToBalance] = useState<IAreaToBalance[]>([]);
  const [loading, setLoading] = useState(true);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        const response = await api.get(`lifeAreas-quadrants/${user._id}/all`);

        const journeyResponse = await api.get(`journeys/${user._id}`);

        setQuadrants(response.data.quadrants);
        setLifeAreas(response.data.lifeAreas);
        setLifeAreasStates(response.data.areaStates);
        setAreasToBalance(journeyResponse.data.areasToBalance);
        if (
          response.data.areaStates.length > 0 &&
          journeyResponse.data.confirmed
        ) {
          setDidComplete(true);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [user._id]);

  const handleUpdateLifeAreasState = useCallback(
    (lifeAreaId: string, value: string) => {
      const lifeAreaUpdated = lifeAreasStates.find(
        area => area.lifeArea === lifeAreaId,
      );

      if (!lifeAreaUpdated) return;

      lifeAreaUpdated.current = Number(value);

      const lifeAreasStatesUpdated = lifeAreasStates.map(lifeArea => {
        if (lifeArea.lifeArea === lifeAreaUpdated.lifeArea) {
          return {
            ...lifeAreaUpdated,
          };
        }

        return lifeArea;
      });

      setLifeAreasStates(lifeAreasStatesUpdated);
    },
    [lifeAreasStates],
  );

  const handleUpdateLifeAreas = (): void => {
    api
      .put(`journeys/${user._id}`, {
        areaStates: lifeAreasStates,
      })
      .then(() => setIsUpdateModalVisible(true))
      .catch(() => message.error('Erro ao atualizar dados'));
  };

  if (loading) {
    return (
      <Layout title="Sua Evolução">
        <Spin size="large" />
      </Layout>
    );
  }

  if (!didComplete) {
    return (
      <Layout title="Sua Evolução">
        <Container>
          <h2>Você ainda não preencheu ou confirmou sua planilha.</h2>
          <p>Complete para continuar</p>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout title="Sua Evolução">
      <Container>
        <div className="info-area">
          <div className="title">
            <h2>Atualize as informações atuais na sua Avaliação Inicial</h2>
            <Button
              type="primary"
              style={{ borderRadius: 20, fontWeight: 'bold' }}
              onClick={() => setIsVideoModalVisible(true)}
            >
              Ver como
            </Button>
          </div>
          <div id="confirm-area">
            <Button
              icon={<MdUpdate />}
              type="primary"
              onClick={handleUpdateLifeAreas}
            >
              Atualizar
            </Button>
          </div>
        </div>

        {quadrants.map(quadrant => (
          <QuadrantArea key={quadrant._id}>
            <div
              className="quadrant-card"
              style={{
                background: quadrant.color,
                minWidth: 200,
              }}
            >
              {quadrant.name}
            </div>

            {quadrant.lifeAreas.map(lifeArea => {
              const lifeAreaIndex = lifeAreas.findIndex(
                la => String(la._id) === String(lifeArea),
              );

              const areaStateIndex = lifeAreasStates.findIndex(
                areaState => String(areaState.lifeArea) === String(lifeArea),
              );

              let areaState = {} as ILifeAreaState;

              if (areaStateIndex >= 0) {
                areaState = lifeAreasStates[areaStateIndex];
              }

              if (lifeAreaIndex < 0) return <></>;

              const lifeAreaObj = lifeAreas[lifeAreaIndex];

              const selected = areasToBalance.find(
                area => area.lifeArea === lifeArea,
              );

              return (
                <Card
                  className="life-area-card"
                  key={lifeAreaObj._id}
                  title={lifeAreaObj.name}
                  headStyle={{ backgroundColor: lifeAreaObj.color }}
                  extra={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Button
                      style={{ borderRadius: 20 }}
                      onClick={() => {
                        setCurrentDescription(lifeAreaObj.description);
                        setIsModalVisible(true);
                      }}
                    >
                      Mais
                    </Button>
                  }
                  style={{
                    minWidth: 200,
                    border: `1px solid ${lifeAreaObj.color}`,
                    backgroundColor: selected ? lifeAreaObj.color : undefined,
                  }}
                >
                  <div className="inputs-group">
                    <div className="input-group">
                      <h4>Inicial</h4>
                      <Input
                        value={areaState.initial}
                        max={10}
                        min={0}
                        type="number"
                        required
                        disabled
                      />
                    </div>
                    <div className="input-group">
                      <h4>Atual</h4>
                      <Input
                        value={areaState.current || undefined}
                        onChange={e => {
                          handleUpdateLifeAreasState(lifeArea, e.target.value);
                        }}
                        max={10}
                        min={0}
                        type="number"
                        disabled={!selected}
                      />
                    </div>
                    <div className="input-group">
                      <h4>Desejado</h4>
                      <Input
                        value={areaState.desired}
                        max={10}
                        min={0}
                        type="number"
                        required
                        disabled
                      />
                    </div>
                  </div>

                  {selected && (
                    <div className="position-badge">{selected.position}</div>
                  )}
                </Card>
              );
            })}
          </QuadrantArea>
        ))}

        <Modal
          title="Instruções"
          centered
          visible={isVideoModalVisible}
          onCancel={() => setIsVideoModalVisible(false)}
          onOk={() => setIsVideoModalVisible(false)}
          closable
          width={750}
          footer={[
            <Button
              type="primary"
              onClick={() => setIsVideoModalVisible(false)}
            >
              Ok
            </Button>,
          ]}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <h2>Assista o vídeo para mais informações sobre a Sua Evolução</h2>

            <br />

            <iframe
              title="Avaliação Inicial"
              width="700"
              height="415"
              src="https://www.youtube.com/embed/o1hRuyrIDXE"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Modal>

        <Modal
          title="Detalhes"
          centered
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onOk={() => setIsModalVisible(false)}
          closable
          footer={[
            <Button type="primary" onClick={() => setIsModalVisible(false)}>
              Ok
            </Button>,
          ]}
        >
          {currentDescription}
        </Modal>

        <Modal
          title="Jornada atualizada"
          centered
          visible={isUpdateModalVisible}
          onCancel={() => setIsUpdateModalVisible(false)}
          onOk={() => setIsUpdateModalVisible(false)}
          closable
          footer={[
            <Button onClick={() => setIsUpdateModalVisible(false)}>Ok</Button>,
          ]}
        >
          <p>
            Sua jornada e estado atual foram atualizados com sucesso! <br />
            Visualize seu Dashboard em tempo real!
          </p>
          <Link to="/dashboard">Ir para Dashboard</Link>
        </Modal>

        <Modal
          title="Detalhes"
          centered
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onOk={() => setIsModalVisible(false)}
          closable
          footer={[
            <Button type="primary" onClick={() => setIsModalVisible(false)}>
              Ok
            </Button>,
          ]}
        >
          {currentDescription}
        </Modal>
      </Container>
    </Layout>
  );
};

export default LifeEvolution;
