import { Switch } from 'react-router-dom';

import Route from './Route';

import Dashboard from '../pages/Dashboard';
import NotFound from '../pages/NotFound';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import LifeSheet from '../pages/LifeSheet';
import BalanceAreas from '../pages/BalanceAreas';
import LifeEvolution from '../pages/LifeEvolution';
import Challenges from '../pages/Challenges';
import BehavioralReport from '../pages/BehavioralReport';
import TheStartup from '../pages/TheStartup';
import Guidevideo from '../pages/Guide';
import FinishJourney from '../pages/FinishJourney';
import { useAuth } from '../hooks/auth';
import Payment from '../pages/Payment';
import CreatePayment from '../pages/CreatePayment';

const Routes: React.FC = () => {
  const { userCanExtendJourney } = useAuth();

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/sign-up" component={SignUp} />

      <Route path="/forgot-password" component={ForgotPassword} />

      <Route path="/dashboard" isPrivate component={Dashboard} />

      <Route path="/startup" isPrivate component={TheStartup} />

      <Route path="/life-sheet" isPrivate component={LifeSheet} />

      <Route path="/balance-areas" isPrivate component={BalanceAreas} />

      <Route path="/life-evolution" isPrivate component={LifeEvolution} />

      <Route path="/challenges" isPrivate component={Challenges} />

      <Route path="/behavioral-report" isPrivate component={BehavioralReport} />

      <Route path="/guide" isPrivate component={Guidevideo} />

      {userCanExtendJourney() && (
        <Route path="/finish-journey" isPrivate component={FinishJourney} />
      )}
      <Route path="/payment" isPrivate component={Payment} />

      <Route
        path="/get-payment-feedback/:email/:days"
        isPrivate
        component={CreatePayment}
      />

      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
