import { AuthProvider } from './auth';
import { SideMenuProvider } from './sidemenu';

const AppProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <SideMenuProvider>{children}</SideMenuProvider>
    </AuthProvider>
  );
};

export default AppProvider;
