import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 15px;
  margin: 10px 20px;
  margin-right: 0;
  border-radius: 6px 0 0 6px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  justify-content: space-between;

  .title-control {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      background: none;
      border: none;
      display: flex;
      align-items: center;

      svg {
        width: 30px;
        height: 30px;
        color: #6b0a95;
      }
    }

    > h1 {
      font-size: 20px;
      font-weight: bold;
      margin-left: 10px;
      color: #6b0a95;
    }
  }

  > h2 {
    font-size: 20px;
    color: #6b0a95;
  }
`;
