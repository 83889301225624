import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;

  .info-area {
    display: flex;

    align-items: center;

    justify-content: space-between;

    .title {
      display: flex;
      gap: 10px;
    }
  }

  #confirm-area {
    display: flex;
    align-items: center;
  }
`;

export const QuadrantArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;

  > div {
    margin-right: 50px;
    align-self: flex-start;
  }

  & + div {
    margin-top: 10px;
  }

  .quadrant-card {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    border-radius: 5px;

    height: 66px;

    font-family: 'Baloo Bhai 2';
    font-weight: 500;

    -webkit-box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.67);
    -moz-box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.67);
    box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.67);
  }

  .life-area-card {
    border-radius: 5px;
  }
`;
