import { Button, Card, message, Spin, Popover, Row, Col } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { format, parseISO, isAfter } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BsCollectionFill } from 'react-icons/bs';
import { MdCheck, MdKeyboardArrowLeft } from 'react-icons/md';
import Layout from '../../components/Layout';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import configHeader from '../../services/headers';
import { getYouTubeId } from '../../utils/getVideoID';

import { Container, OptionJourney } from './styles';

interface Challenge {
  _id: string;

  num: number;
  name: string;
  description?: string;
  lifeArea: {
    name: string;
  };
  quadrant: {
    name: string;
    color: string;
  };

  video?: {
    _id: string;
    videoUrl: string;
  };

  level: 'easy' | 'medium' | 'hard';
}

interface ExtendedChallenge {
  challenge: string;
  position?: number;
}

interface NextJourney {
  _id: string;
  currentArea: string;
  currentAreaName: string;
  nextArea?: string;
  nextAreaName?: string;
  nextAreaColor?: string;
}

const FinishJourney: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [extendChallenges, setExtendChallenges] = useState<ExtendedChallenge[]>(
    [],
  );
  const [optionsNextJourney, setOptionsNextJourney] = useState(true);
  const [nextJourney, setNextJourney] = useState<NextJourney | null>(null);

  const history = useHistory();
  const { user, updateUser } = useAuth();

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get(`challenges/${user._id}/incomplete`);
        setChallenges(response.data);

        await api
          .get(`journeys/${user._id}/nextjourney`)
          .then(responseNextJourney => {
            setNextJourney(responseNextJourney.data);
          })
          .catch(err => setOptionsNextJourney(false));
      } catch (err) {
        message.error('Erro ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();

    // loadPaymentDataUser();
  }, [user._id]);

  async function loadPaymentDataUser(): Promise<void> {
    const response = await api.post('/get-payment-data-user', {
      email: user.email,
    });

    try {
      if (response.data.payment_id) {
        const responseMP = await axios.get(
          `https://api.mercadopago.com/v1/payments/${response.data.payment_id}`,
          configHeader,
        );

        if (
          responseMP.data.status !== 'approved' ||
          isAfter(new Date(), new Date(response.data.next_payment_data))
        ) {
          history.push('/payment');
        }
      } else {
        history.push('/finish-journey');
      }
    } catch (err) {
      message.error(err);
    }
  }

  const handlUpdateExtendChallenges = useCallback(
    (challengeId: string) => {
      const exists = extendChallenges.find(
        challenge => String(challenge.challenge) === String(challengeId),
      );

      if (extendChallenges.length >= 7 && !exists) return;

      let updatedExtendeChallenges: ExtendedChallenge[] = [];

      if (exists) {
        updatedExtendeChallenges = extendChallenges.filter(
          challenge => challenge.challenge !== exists.challenge,
        );
      } else {
        updatedExtendeChallenges = [
          ...extendChallenges,
          {
            challenge: challengeId,
          },
        ];
      }

      const newExtendChallenges = updatedExtendeChallenges.map(
        (challenge, index) => ({
          challenge: challenge.challenge,
          position: index + 1,
        }),
      );

      setExtendChallenges(newExtendChallenges);
    },
    [extendChallenges],
  );

  const handleExtendJourney = async (): Promise<void> => {
    confirm({
      title: 'Você deseja estender sua jornada?',
      content: `Se sim, você receberá um email para realizar o primeiro desafio selecionado às ${
        user.journey.dailyChallengesHour || '08:00'
      }h`,
      okText: 'Sim',
      cancelText: 'Cancelar',
      onOk: async () => {
        if (extendChallenges.length <= 0) {
          message.warn(
            'Você precisa selecionar no mínimo um desafio para completar',
          );
          return;
        }
        try {
          const response = await api.put(`journeys/${user._id}/extend`, {
            extendChallenges,
          });

          const journey = response.data;

          updateUser({ ...user, journey });
          message.success('Sua jornada foi estendida com sucesso!');
        } catch (error) {
          message.error('Erro ao estender sua jornada. Tente novamente');
        }
      },
    });
  };

  const formattedInitialDate = useMemo(() => {
    if (!user.journey.initialDate) {
      return '';
    }

    const formatted = format(parseISO(user.journey.initialDate), 'dd/MM/yyyy');

    return formatted;
  }, [user.journey.initialDate]);

  const formattedFinalDate = useMemo(() => {
    if (!user.journey.finalDate) {
      return '';
    }

    const formatted = format(parseISO(user.journey.finalDate), 'dd/MM/yyyy');

    return formatted;
  }, [user.journey.finalDate]);

  const handleNextJourney = async (): Promise<void> => {
    confirm({
      title: `Deseja iniciar a jornada ${nextJourney?.nextAreaName}`,
      content: `Se sim, você receberá um email para realizar o primeiro desafio selecionado às ${
        user.journey.dailyChallengesHour || '08:00'
      }h`,
      okText: 'Sim',
      cancelText: 'Cancelar',
      onOk: async () => {
        try {
          const response = await api.post(`journeys/${user._id}/newjourney`);

          const journey = {
            _id: response.data._id,
            dailyChallengesHour: response.data.dailyChallengesHour,
            finalDate: response.data.finalDate,
            initialDate: response.data.initialDate,
            extendDate: response.data.extendDate,
          };

          updateUser({ ...user, journey });

          message.success(
            'Sua jornada foi criada com sucesso, aguarde suas atividades!',
          );
          history.push('/dashboard');
        } catch (error) {
          message.error('Erro ao iniciar a próxima jornada. Tente novamente');
        }
      },
    });
  };

  if (loading) {
    return (
      <Layout title="Atividades">
        <Spin size="large" />
      </Layout>
    );
  }

  return (
    <>
      <Layout title="Atividades">
        <Container
          color={
            challenges.length > 0 ? challenges[0].quadrant.color : '#404040'
          }
          colorNextJourney={
            nextJourney?.nextAreaColor ? nextJourney.nextAreaColor : '#404040'
          }
        >
          <div className="title-finished-journey">
            <h1>
              Parabéns, você finalizou sua jornada{' '}
              <span>
                {challenges.length > 0 && challenges[0].lifeArea.name}
              </span>{' '}
            </h1>
            <span>
              Início: {formattedInitialDate} | Final: {formattedFinalDate}
            </span>
          </div>
          {optionsNextJourney ? (
            <>
              <div className="challenges-incomplete-popover">
                {challenges.length > 0 && (
                  <h2>
                    Durante esse período você realizou {21 - challenges.length}{' '}
                    de {challenges.length} desafíos
                    {/* <Popover
                    title={`${challenges.length} desafíos não completos`}
                    content={() => (
                      <div style={{ lineHeight: '0.7' }}>
                        {challenges.length > 0 &&
                          challenges.map((item, index) => (
                            <p>
                              {index + 1} - {item.name}
                            </p>
                          ))}
                      </div>
                    )}
                  >
                    {challenges.length - 21} de {challenges.length} desafíos
                  </Popover> */}
                    , antes de iniciar a proxima jornada{' '}
                    {!!nextJourney && (
                      <>
                        <span>{nextJourney?.nextAreaName}</span>
                      </>
                    )}
                    , você poderá refazer até 7 desafíos.
                  </h2>
                )}
              </div>
              <div className="actions-journey-container">
                {challenges.length > 0 && (
                  <OptionJourney
                    color="#00A2B7"
                    onClick={() => setOptionsNextJourney(false)}
                  >
                    <h2>Refazer desafíos</h2>
                    <p>
                      Você poderá escolher até 7 disafíos para refazer antes de
                      iniciar sua próxima jornada.
                    </p>
                  </OptionJourney>
                )}

                {!!nextJourney && (
                  <OptionJourney color="#6B0767" onClick={handleNextJourney}>
                    <h2>Iniciar próxima jornada</h2>
                    <p>
                      Inicie sua proxima jornada {nextJourney?.nextAreaName}, os
                      novos desafíos serão enviados amanhã às{' '}
                      {user.journey.dailyChallengesHour}
                    </p>
                  </OptionJourney>
                )}
                <OptionJourney
                  color="#ffc61a"
                  onClick={() => history.push('/balance-areas')}
                >
                  <h2>Selecionar outra área para equilibrar</h2>
                  <p>
                    Caso deseje alterar ou visualizar as Áreas para participar
                  </p>
                </OptionJourney>
              </div>
            </>
          ) : (
            <>
              <h2 style={{ textAlign: 'center' }}>
                {/* Selecione todos os desafios não completos que você deseja
                refazer (Máximo 7 desafios selecionados) */}
                Selecione até 7 desafíos que deseja refazer.
              </h2>

              <div className="confirma-button-area">
                {!!nextJourney && (
                  <Button
                    icon={<MdKeyboardArrowLeft />}
                    type="primary"
                    danger
                    onClick={() => setOptionsNextJourney(true)}
                  >
                    Voltar
                  </Button>
                )}
                <Button
                  icon={<MdCheck />}
                  type="primary"
                  onClick={handleExtendJourney}
                >
                  Confirmar
                </Button>
              </div>

              <div className="challenge-grid">
                {challenges.map(challenge => {
                  const selected = extendChallenges.find(
                    challengeObj => challengeObj.challenge === challenge._id,
                  );

                  return (
                    <Card
                      className="challenge-card"
                      key={challenge._id}
                      onClick={() => handlUpdateExtendChallenges(challenge._id)}
                      hoverable
                      style={{ width: 220 }}
                      cover={
                        challenge.video?.videoUrl ? (
                          <img
                            alt={challenge.name}
                            src={`https://img.youtube.com/vi/${getYouTubeId(
                              challenge.video.videoUrl,
                            )}/0.jpg`}
                          />
                        ) : (
                          <BsCollectionFill size={164} color="#a6a6a6" />
                        )
                      }
                    >
                      <Card.Meta title={challenge.name} />
                      <span>Quadrante: {challenge.quadrant.name}</span>
                      <span>Área: {challenge.lifeArea.name}</span>
                      <span>Dia: {challenge.num}</span>

                      {selected && (
                        <div className="position-badge">
                          {selected.position}
                        </div>
                      )}
                    </Card>
                  );
                })}
              </div>
            </>
          )}
        </Container>
      </Layout>
    </>
  );
};

export default FinishJourney;
