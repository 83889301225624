import {
  MdDashboard,
  MdNote,
  MdPlayCircleOutline,
  MdNoSim,
} from 'react-icons/md';
import { CgLogOut } from 'react-icons/cg';
import {
  BsCollectionFill,
  BsFileEarmarkSpreadsheet,
  BsFillPieChartFill,
} from 'react-icons/bs';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { BiLineChart } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import logoImg from '../../assets/images/reconectme_logo_positivo.png';
import logoImgIcon from '../../assets/images/reconectme_icone_positivo.png';

import { Container, MenuItem, MenuItemButton } from './styles';
import { useAuth } from '../../hooks/auth';
import { useSideMenu } from '../../hooks/sidemenu';

const SideMenu: React.FC = () => {
  const { signOut, userCanExtendJourney } = useAuth();
  const { isDrawerOpen } = useSideMenu();
  const { pathname } = useLocation();

  const handleLogOut = (): void => {
    signOut();
  };

  return (
    <Container $isOpen={isDrawerOpen}>
      <img src={isDrawerOpen ? logoImg : logoImgIcon} alt="Reconect Me" />

      <div className="line" />

      <MenuItem
        $isOpen={isDrawerOpen}
        $isSelected={pathname === '/dashboard'}
        to="/dashboard"
      >
        <MdDashboard />
        <span>Dashboard</span>
      </MenuItem>

      <MenuItem
        $isOpen={isDrawerOpen}
        $isSelected={pathname === '/startup'}
        to="/startup"
      >
        <IoMdInformationCircleOutline />
        <span>A Startup</span>
      </MenuItem>

      <MenuItem
        $isOpen={isDrawerOpen}
        $isSelected={pathname === '/life-sheet'}
        to="/life-sheet"
      >
        <BsFileEarmarkSpreadsheet />
        <span>Avaliação inicial</span>
      </MenuItem>

      <MenuItem
        $isOpen={isDrawerOpen}
        $isSelected={pathname === '/balance-areas'}
        to="/balance-areas"
      >
        <BsFillPieChartFill />
        <span>Áreas para Participar</span>
      </MenuItem>

      <MenuItem
        $isOpen={isDrawerOpen}
        $isSelected={pathname === '/life-evolution'}
        to="/life-evolution"
      >
        <BiLineChart />
        <span>Sua evolução</span>
      </MenuItem>

      {!userCanExtendJourney() ? (
        <MenuItem
          $isOpen={isDrawerOpen}
          $isSelected={pathname === '/challenges'}
          to="/challenges"
        >
          <BsCollectionFill />
          <span>Atividades</span>
        </MenuItem>
      ) : (
        <MenuItem
          $isOpen={isDrawerOpen}
          $isSelected={pathname === '/finish-journey'}
          to="/finish-journey"
        >
          <MdNoSim />
          <span>Finalize sua jornada</span>
        </MenuItem>
      )}

      <MenuItem
        $isOpen={isDrawerOpen}
        $isSelected={pathname === '/behavioral-report'}
        to="/behavioral-report"
      >
        <MdNote />
        <span>Laudo Comportamental</span>
      </MenuItem>

      <MenuItem
        $isOpen={isDrawerOpen}
        $isSelected={pathname === '/guide'}
        to="/guide"
      >
        <MdPlayCircleOutline />
        <span>Guia</span>
      </MenuItem>

      <MenuItemButton $isOpen={isDrawerOpen} onClick={handleLogOut}>
        <CgLogOut />
        <span>Sair</span>
      </MenuItemButton>
    </Container>
  );
};

export default SideMenu;
