import { useState } from 'react';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';

import { Container } from './styles';

const BehavioralReport: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(true);

  const history = useHistory();

  return (
    <Layout title="Laudo Comportamental">
      <Container>
        <h2>Laudo Comportamental</h2>

        <Modal
          title="Detalhes"
          centered
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onOk={() => setIsModalVisible(false)}
          closable
          footer={[
            <Button
              type="primary"
              onClick={() => {
                setIsModalVisible(false);
                history.push('/dashboard');
              }}
            >
              Ok
            </Button>,
          ]}
        >
          <p>Laudo de perfil comportamental disponível na versão Premium!</p>
        </Modal>
      </Container>
    </Layout>
  );
};

export default BehavioralReport;
