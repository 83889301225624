import axios from 'axios';
import { isAfter, parseISO, startOfDay } from 'date-fns';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import api from '../services/api';

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  tenant: string | Tenant;
  email: string;
  access: {
    master?: boolean;
    system?: boolean;
    admin?: boolean;
    blocked?: boolean;
  };
  journey: {
    initialDate?: string;
    finalDate?: string;
    extendDate?: string;
    // Time on the format 'HH:mm'
    dailyChallengesHour?: string;
    _id?: string;
  };
}

interface Tenant {
  _id: string;
  cnpj: string;
  name: string;
  shortName: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredencials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  userCanExtendJourney(): boolean;
  signIn(credencials: SignInCredencials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

const url =
  process.env.REACT_APP_ENVIRONMENT === 'development'
    ? 'http://localhost:3333'
    : process.env.REACT_APP_API_URL;

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@ReconectMeApp:token');
    const user = localStorage.getItem('@ReconectMeApp:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      api.defaults.baseURL = `${url}/${JSON.parse(user).tenant._id}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  useEffect(() => {
    async function verifyToken(): Promise<void> {
      const token = localStorage.getItem('@ReconectMeApp:token');

      if (token) {
        try {
          const response = await axios.post(`${url}/sessions/test-token`, {
            token,
          });

          const isTokenValid = response.data.isValid;

          if (!isTokenValid || response.status === 401) {
            throw new Error();
          }
        } catch (error) {
          localStorage.removeItem('@ReconectMeApp:token');
          localStorage.removeItem('@ReconectMeApp:user');

          api.defaults.baseURL = url;

          setData({} as AuthState);
        }
      }
    }

    verifyToken();
  }, []);

  const signIn = useCallback(async ({ email, password }): Promise<void> => {
    const response = await api.post('sessions/login', { email, password });

    const { token, user } = response.data;

    localStorage.setItem('@ReconectMeApp:token', token);
    localStorage.setItem('@ReconectMeApp:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;
    api.defaults.baseURL = `${url}/${user.tenant._id}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@ReconectMeApp:token');
    localStorage.removeItem('@ReconectMeApp:user');

    api.defaults.baseURL = url;

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@ReconectMeApp:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [data.token],
  );

  const userCanExtendJourney = useCallback((): boolean => {
    if (!data.user || !data.user.journey) return false;

    const { finalDate, extendDate } = data.user.journey;
    if (
      finalDate &&
      isAfter(new Date(), startOfDay(parseISO(finalDate))) &&
      !extendDate
    ) {
      return true;
    }
    return false;
  }, [data]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        userCanExtendJourney,
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}
