import { darken } from '@material-ui/core';
import styled from 'styled-components';

interface IContainer {
  color?: string;
  colorNextJourney?: string;
}

export const Container = styled.div<IContainer>`
  > h1,
  > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .title-finished-journey {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 > span {
      color: ${props => props.color};
    }
  }

  .challenges-incomplete-popover {
    span {
      color: ${props => props.colorNextJourney};
    }
  }

  .challenges-incomplete-popover {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    h2 {
      margin-left: 65px;
      margin-right: 65px;
      padding: 15px;
      text-align: center;
    }
  }

  .confirma-button-area {
    display: flex;
    justify-content: center;

    button {
      margin: 20px;
    }
  }

  .actions-journey-container {
    display: grid;
    flex: 1;
    grid-template-columns: repeat(3, 1fr);
    column-count: 2;
    column-gap: 20px;
    justify-items: center;
    align-items: center;
    gap: 15px;
    padding: 30px;
    margin-top: 30px;
  }

  button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #048ba8;
    color: #fff;
    border-radius: 4px;
    padding: 10px 20px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    transition: 0.2s;

    svg {
      color: #fff;
    }

    &:hover {
      background: ${darken('#048ba8', 0.15)};
      color: #fff;
    }
  }

  #controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .challenge-grid {
    margin-top: 20px;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    row-gap: 25px;

    .challenge-card {
      position: relative;
      border-radius: 5px;
    }

    .position-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 29px;
      height: 29px;
      background-color: red;
      box-shadow: 1px 1px 3px gray;
      border-radius: 50%;
      color: #fff;
      font-weight: 600;

      position: absolute;

      top: -5px;
      right: -5px;
    }

    span {
      display: block;
    }

    a {
      width: fit-content;

      .card {
        border-radius: 4px;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
        transition: 0.2s;

        &:hover {
          background-color: ${darken('#fff', 0.018)};
        }
      }
    }
  }
`;

export const OptionJourney = styled.a`
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: ${props => props.color};
  color: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  border-radius: 10px;
  width: 300px;
  height: 180px;
  margin: 35px;
  &:hover {
    filter: brightness(0.9);
    color: #fff;
  }

  h2 {
    color: #fff;
    font-size: 18px;
    padding: 10px;
  }

  p {
    padding: 15px;
    color: #fff;
  }
`;
