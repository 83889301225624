import React from 'react';
import Layout from '../../components/Layout';

import { Container } from './styles';

const TheStartup: React.FC = () => {
  return (
    <Layout title="A Startup">
      <Container>
        <h1>Sobre a Startup</h1>

        <div className="video-content">
          <iframe
            title="Reconect Me"
            width="1024"
            height="576"
            src="https://www.youtube.com/embed/C465iWW9NVo"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Container>
    </Layout>
  );
};

export default TheStartup;
