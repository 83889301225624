import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    color: #272e4f;
  }

  > img {
    max-width: 600px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
  }
`;
