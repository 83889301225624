import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;

  .info-area {
    display: flex;

    align-items: center;

    justify-content: space-between;
  }

  .title {
    display: flex;
    gap: 10px;
  }

  #confirm-area {
    display: flex;
    align-items: center;
  }
`;

export const QuadrantArea = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;

  > div {
    margin-right: 50px;
    align-self: flex-start;
  }

  & + div {
    margin-top: 10px;
  }

  .quadrant-card {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    height: 66px;

    border-radius: 5px;

    font-family: 'Baloo Bhai 2';
    font-weight: 500;

    -webkit-box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.67);
    -moz-box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.67);
    box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.67);
  }

  .life-area-card {
    position: relative;
    border-radius: 5px;
  }

  .position-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background-color: red;
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    border: 1px solid #666;

    position: absolute;

    top: -5px;
    right: -5px;
  }
`;
