import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10vh;

  > img {
    width: 300px;
    margin: 50px 0;
  }

  > h1 {
    margin-top: 20px;
    font-size: 24px;
    margin-bottom: 20px;
  }
`;

export const Content = styled.div`
  background-color: #fbfbfb;
  padding: 30px;
  border-radius: 6px;
  width: 100%;
  max-width: 800px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;

  .steps-content {
    margin-bottom: 20px;
  }

  .confirm-data {
    font-size: 14px;
    display: flex;
    flex-direction: column;

    .data {
      display: flex;
      gap: 20px;

      div {
        strong {
          font-size: 14px;
        }
        display: flex;
        flex-direction: column;
      }
    }

    h4 {
      font-size: 15px;
    }
  }

  .steps-action {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const AddForm = styled.form`
  margin-top: 30px;

  h3 {
    opacity: 0.7;
    margin-bottom: 25px;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    strong {
      font-size: 18px;
    }
  }

  .input-group {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    margin-bottom: 10px;
    width: 100%;
  }

  .inputs-group {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    display: flex;
    gap: 16px;
  }

  .button-group {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    button {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      font-weight: bold;
    }
  }
`;
