import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    color: #272e4f;
  }

  > img {
    max-width: 600px;
  }
`;
