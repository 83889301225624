import { Button, Modal } from 'antd';
import { FormEvent, useCallback, useRef, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import LinkMd from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link, useHistory } from 'react-router-dom';
import { message } from 'antd';
import { ButtonBlue, ContainerDiv, MainDiv } from './styles';
import logoImg from '../../assets/images/reconectme_logo_positivo.png';
import { useAuth } from '../../hooks/auth';

const Copyright: React.FC = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <LinkMd color="inherit" href="#a">
        ReconectMe
      </LinkMd>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
};

const SignIn: React.FC = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);

  const history = useHistory();
  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      if (!emailRef.current?.value || !passwordRef.current?.value) {
        message.error('Preencha todos os campos obrigatórios (*)');
        return;
      }

      try {
        await signIn({
          email: emailRef.current.value,
          password: passwordRef.current.value,
        });

        history.push('/dashboard');
      } catch (err: any) {
        message.error(`Erro na autenticação ${err.response.data.message}`);
      }
    },
    [history, signIn],
  );

  return (
    <ContainerDiv maxWidth="xs">
      <CssBaseline />
      <MainDiv>
        <img alt="ReconectMe" src={logoImg} />
        <br />
        <div className="title">
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Button
            type="primary"
            style={{ borderRadius: 20, fontWeight: 'bold', marginTop: 15 }}
            onClick={() => setIsVideoModalVisible(true)}
          >
            Como criar sua nova Conta!
          </Button>
        </div>
        <form onSubmit={e => handleSubmit(e)}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            type="email"
            inputRef={emailRef}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            inputRef={passwordRef}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Lembrar de mim"
          /> */}
          <ButtonBlue type="submit" fullWidth variant="contained">
            Entrar
          </ButtonBlue>
          <Grid container>
            <Grid item xs>
              <Link to="/forgot-password">Esqueci a senha</Link>
            </Grid>
            <Grid item>
              <Link to="/sign-up">Cadastre-se</Link>
            </Grid>
          </Grid>
        </form>
      </MainDiv>
      <Box mt={8}>
        <Copyright />
      </Box>

      <Modal
        title="Instruções"
        centered
        visible={isVideoModalVisible}
        onCancel={() => setIsVideoModalVisible(false)}
        onOk={() => setIsVideoModalVisible(false)}
        closable
        width={750}
        footer={[
          <Button type="primary" onClick={() => setIsVideoModalVisible(false)}>
            Ok
          </Button>,
        ]}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h2>
            Assista o vídeo para informações de como criar sua nova Conta!
          </h2>

          <br />

          <iframe
            title="Avaliação Inicial"
            width="700"
            height="415"
            src="https://www.youtube.com/embed/Jv9ypgsCxLM"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Modal>
    </ContainerDiv>
  );
};

export default SignIn;
