import {
  Button,
  Card,
  Input,
  message,
  Modal,
  Select,
  Spin,
  TimePicker,
  Tooltip,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { MdCheck } from 'react-icons/md';
import Layout from '../../components/Layout';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import { Container, QuadrantArea, AreaSelected } from './styles';

interface IQuadrant {
  _id: string;
  name: string;
  color: string;
  description?: string;
  lifeAreas: string[];
}

interface ILifeArea {
  _id: string;
  name: string;
  color: string;
  description: string;
  initial?: number;
  desired?: number;
}

interface ILifeAreaState {
  lifeArea: string;
  initial?: number;
  desired?: number;
  current?: number;
  selected?: boolean;
  position?: number;
}

interface IAreaToBalance {
  lifeArea: string;
  position?: number;
  currentArea?: boolean;
}

interface IDataCard {
  description: string;
  value: number;
  color: string;
  cardActived: boolean;
}

const BalanceAreas: React.FC = () => {
  const [quadrants, setQuadrants] = useState<IQuadrant[]>([]);
  const [lifeAreas, setLifeAreas] = useState<ILifeArea[]>([]);
  const [lifeAreasStates, setLifeAreasStates] = useState<ILifeAreaState[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [didComplete, setDidComplete] = useState(false);
  const [didCompleteSheet, setDidCompleteSheet] = useState(false);
  const [areasToBalance, setAreasToBalance] = useState<IAreaToBalance[]>([]);
  const [currentDescription, setCurrentDescription] = useState('');
  const [currentAreaId, setCurrentAreaId] = useState('');
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [level, setLevel] = useState('easy');
  const [dailyChallengeHour, setDailyChallengeHour] = useState('08:00');
  const [loading, setLoading] = useState(true);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        const response = await api.get(`lifeAreas-quadrants/${user._id}/all`);

        const journeyResponse = await api.get(`journeys/${user._id}`);
        setQuadrants(response.data.quadrants);
        setLifeAreas(response.data.lifeAreas);
        setLifeAreasStates(response.data.areaStates);
        const areasToBalanceMap = journeyResponse.data.areasToBalance.map(
          (area: IAreaToBalance) => ({
            ...area,
            currentArea: area.lifeArea === journeyResponse.data.currentArea,
          }),
        );
        setCurrentAreaId(journeyResponse.data.currentArea);
        setAreasToBalance(areasToBalanceMap);
        if (journeyResponse.data.confirmed) setDidComplete(true);
        if (response.data.areaStates.length > 0) setDidCompleteSheet(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [user._id]);

  const handlUpdateAreaToBalance = useCallback(
    (lifeArea: ILifeArea, cardActived: IDataCard) => {
      if (!cardActived.cardActived) {
        if (cardActived.value === 2) {
          message.info('Essa é sua jornada atual');
        }
        if (cardActived.value === 1) {
          message.success('Essa jornada já foi concluída');
        }
        return;
      }

      const exists = areasToBalance.find(
        area => String(area.lifeArea) === String(lifeArea._id),
      );
      let updatedAreasToBalance: IAreaToBalance[] = [];

      if (exists) {
        updatedAreasToBalance = areasToBalance.filter(
          area => area.lifeArea !== exists.lifeArea,
        );
      } else {
        updatedAreasToBalance = [
          ...areasToBalance,
          {
            lifeArea: lifeArea._id,
          },
        ];
      }

      const newAreasToBalance = updatedAreasToBalance.map((area, index) => ({
        lifeArea: area.lifeArea,
        position: index + 1,
        currentArea: area.lifeArea === currentAreaId,
      }));

      setAreasToBalance(newAreasToBalance);
      setDidComplete(false);
    },
    [areasToBalance],
  );

  const handleConfirmJourney = (): void => {
    if (areasToBalance.length <= 0) {
      message.warn('Escolha pelo menos uma área para equilibrar');
      return;
    }

    if (!level || !dailyChallengeHour) {
      message.warn('Preencha as informações para confirmar');
      return;
    }

    api
      .put(`journeys/${user._id}/confirm`, {
        areasToBalance,
        level,
        dailyChallengesHour: dailyChallengeHour,
      })
      .then(() => {
        setDidComplete(true);
        message.success(
          'Pronto, sua jornada foi definida! Em breve você receberá seus desafíos',
          15,
        );
      })
      .catch(() => message.error('Erro ao confirmar jornada'))
      .finally(() => setIsConfirmModalVisible(false));
  };

  if (loading) {
    return (
      <Layout title="Áreas para participar">
        <Spin size="large" />
      </Layout>
    );
  }

  if (!didCompleteSheet) {
    return (
      <Layout title="Áreas para participar">
        <Container>
          <h2>Você ainda não preencheu sua planilha.</h2>
          <p>Preencha a para continuar</p>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout title="Áreas para participar">
      <Container>
        <div className="info-area">
          {didComplete ? (
            <div className="title">
              <h2>Essas são suas jornadas para o reequilibrio!</h2>

              <Button
                type="primary"
                style={{ borderRadius: 20, fontWeight: 'bold' }}
                onClick={() => setIsVideoModalVisible(true)}
              >
                Ver mais
              </Button>
            </div>
          ) : (
            <>
              <div className="title">
                <h2>Selecione as áreas que deseja equilibrar</h2>
                <Button
                  type="primary"
                  style={{ borderRadius: 20, fontWeight: 'bold' }}
                  onClick={() => setIsVideoModalVisible(true)}
                >
                  Ver como
                </Button>
              </div>
              {areasToBalance.length} áreas selecionadas
              <div id="confirm-area">
                <Button
                  icon={<MdCheck />}
                  type="primary"
                  onClick={() => setIsConfirmModalVisible(true)}
                >
                  Confirmar
                </Button>
              </div>
            </>
          )}
        </div>

        {quadrants.map(quadrant => (
          <QuadrantArea key={quadrant._id}>
            <div
              className="quadrant-card"
              style={{
                background: quadrant.color,
                minWidth: 200,
              }}
            >
              {quadrant.name}
            </div>

            {quadrant.lifeAreas.map(lifeArea => {
              const lifeAreaIndex = lifeAreas.findIndex(
                la => la._id === lifeArea,
              );

              const areaStateIndex = lifeAreasStates.findIndex(
                areaState => areaState.lifeArea === lifeArea,
              );

              let areaState = {} as ILifeAreaState;

              if (areaStateIndex >= 0) {
                areaState = lifeAreasStates[areaStateIndex];
              }

              if (lifeAreaIndex < 0) return <></>;

              const lifeAreaObj = lifeAreas[lifeAreaIndex];

              const selected: any = areasToBalance.find(
                area => area.lifeArea === lifeArea,
              );

              const currentAreaPosition: any = areasToBalance.find(
                area => area.currentArea === true,
              );

              const verifyArea = (selectedItem: any, currentArea: any) => {
                let dataArea = {
                  description: '',
                  value: 0,
                  color: '#FF0000',
                  cardActived: true,
                };
                if (selectedItem && currentArea) {
                  if (currentArea.position === selectedItem.position) {
                    dataArea = {
                      description: 'Jornada Atual',
                      value: 2,
                      color: '#0066ff',
                      cardActived: false,
                    };
                  } else if (currentArea.position > selectedItem.position) {
                    dataArea = {
                      description: 'Jornada Concluída',
                      value: 1,
                      color: '#33cc33',
                      cardActived: false,
                    };
                  } else {
                    dataArea = {
                      description: 'Próxima Jornada',
                      value: 0,
                      color: '#FF0000',
                      cardActived: true,
                    };
                  }
                }
                return dataArea;
              };

              return (
                <Tooltip
                  title={verifyArea(selected, currentAreaPosition).description}
                >
                  <Card
                    hoverable={
                      verifyArea(selected, currentAreaPosition).cardActived
                    }
                    className="life-area-card"
                    key={lifeAreaObj._id}
                    title={lifeAreaObj.name}
                    headStyle={{ backgroundColor: lifeAreaObj.color }}
                    onClick={() => {
                      handlUpdateAreaToBalance(
                        lifeAreaObj,
                        verifyArea(selected, currentAreaPosition),
                      );
                    }}
                    extra={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Button
                        style={{ borderRadius: 20 }}
                        onClick={() => {
                          setCurrentDescription(lifeAreaObj.description);
                          setIsModalVisible(true);
                        }}
                      >
                        Mais
                      </Button>
                    }
                    style={{
                      minWidth: 200,
                      border: `1px solid ${lifeAreaObj.color}`,
                      backgroundColor: selected ? lifeAreaObj.color : undefined,
                    }}
                  >
                    <div className="inputs-group">
                      <div className="input-group">
                        <h4>Inicial</h4>
                        <Input
                          disabled
                          value={areaState.initial}
                          type="number"
                          required
                        />
                      </div>
                      <div className="input-group">
                        <h4>Atual</h4>
                        <Input
                          value={areaState.current || undefined}
                          type="number"
                          disabled
                        />
                      </div>
                      <div className="input-group">
                        <h4>Desejado</h4>
                        <Input
                          disabled
                          value={areaState.desired}
                          type="number"
                          required
                        />
                      </div>
                    </div>
                    {selected && (
                      <AreaSelected
                        color={verifyArea(selected, currentAreaPosition).color}
                      >
                        {selected.position}
                      </AreaSelected>
                    )}
                  </Card>
                </Tooltip>
              );
            })}
          </QuadrantArea>
        ))}

        <Modal
          title="Detalhes"
          centered
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onOk={() => setIsModalVisible(false)}
          closable
          footer={[
            <Button type="primary" onClick={() => setIsModalVisible(false)}>
              Ok
            </Button>,
          ]}
        >
          {currentDescription}
        </Modal>

        <Modal
          title="Instruções"
          centered
          visible={isVideoModalVisible}
          onCancel={() => setIsVideoModalVisible(false)}
          onOk={() => setIsVideoModalVisible(false)}
          closable
          width={750}
          footer={[
            <Button
              type="primary"
              onClick={() => setIsVideoModalVisible(false)}
            >
              Ok
            </Button>,
          ]}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <h2>
              Assista o vídeo para mais informações sobre as Áreas para
              Equilibrar
            </h2>

            <br />

            <iframe
              title="Avaliação Inicial"
              width="700"
              height="415"
              src="https://www.youtube.com/embed/ZvAFmV0istM"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Modal>

        <Modal
          title="Detalhes"
          centered
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onOk={() => setIsModalVisible(false)}
          closable
          footer={[
            <Button type="primary" onClick={() => setIsModalVisible(false)}>
              Ok
            </Button>,
          ]}
        >
          {currentDescription}
        </Modal>
        <Modal
          title="Confirmar jornada"
          centered
          visible={isConfirmModalVisible}
          onCancel={() => setIsConfirmModalVisible(false)}
          onOk={() => setIsConfirmModalVisible(false)}
          closable
          footer={[
            <Button onClick={() => setIsConfirmModalVisible(false)}>
              Voltar
            </Button>,
            <Button type="primary" onClick={handleConfirmJourney}>
              Confirmar
            </Button>,
          ]}
        >
          <div className="input-group">
            <h4>Selecione o nível das atividades</h4>
            <Select value={level} onChange={e => setLevel(e)}>
              <Select.Option value="easy">Fácil</Select.Option>
              <Select.Option disabled value="medium">
                Médio
              </Select.Option>
              <Select.Option disabled value="hard">
                Difícil
              </Select.Option>
            </Select>
          </div>

          <div className="input-group">
            <h4>Selecione o horário que deseja receber as atividades</h4>
            <TimePicker
              format="HH:mm"
              value={moment(dailyChallengeHour, 'HH:mm')}
              onChange={(_, timeString) => {
                setDailyChallengeHour(timeString || '08:00');
              }}
            />
          </div>
        </Modal>
      </Container>
    </Layout>
  );
};

export default BalanceAreas;
