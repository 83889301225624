const token =
  'TEST-3607495637967107-020222-72a04ec747570d545780a4edeec986e7-710152864';

const tokenProduction =
  'APP_USR-3607495637967107-020222-fe5930ec450e832b48de8340fad1d440-710152864';

const configHeader = {
  headers: {
    Authorization: `Bearer ${tokenProduction}`,
  },
};

export default configHeader;
