import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import ApexChart from 'react-apexcharts';
import { Button, Spin } from 'antd';
import Layout from '../../components/Layout';
import 'react-svg-radar-chart/build/css/index.css';

import { Container } from './styles';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

interface IJourney {
  areasToBalance: Array<{
    lifeArea: string;
    position: number;
  }>;

  areaStates: Array<{
    lifeArea: string;
    initial: number;
    current: number;
    desired: number;
  }>;

  currentArea?: string;
}

interface IData {
  series: Array<{
    name: string;
    data: number[];
  }>;
  categories: string[];
}

const Dashboard: React.FC = () => {
  const [data, setData] = useState<IData>({} as IData);
  const [loading, setLoading] = useState(true);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        const response = await api.get(`journeys-dashboard/${user._id}`);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    loadData();
  }, [user._id]);

  if (loading) {
    return (
      <Layout title="Dashboard">
        <Spin size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Dashboard">
      <Container>
        <div className="title">
          <h1>Informações</h1>
          <Button
            type="primary"
            style={{ borderRadius: 20, fontWeight: 'bold' }}
            onClick={() => setIsVideoModalVisible(true)}
          >
            Saber mais
          </Button>
        </div>

        <div className="radar-area">
          <ApexChart
            type="radar"
            options={{
              chart: {
                dropShadow: {
                  enabled: true,
                  blur: 1,
                  left: 1,
                  top: 1,
                },
              },
              title: {
                text: 'Áreas',
              },
              stroke: {
                width: 2,
              },
              fill: {
                opacity: 0.1,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                categories: data.categories,
              },
              colors: ['#ff0000', '#FEB019', '#008FFB'],
              noData: {
                text: undefined,
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
              },
            }}
            series={data.series}
            height={500}
            width={500}
          />
        </div>

        <Modal
          title="Instruções"
          centered
          visible={isVideoModalVisible}
          onCancel={() => setIsVideoModalVisible(false)}
          onOk={() => setIsVideoModalVisible(false)}
          closable
          width={750}
          footer={[
            <Button
              type="primary"
              onClick={() => setIsVideoModalVisible(false)}
            >
              Ok
            </Button>,
          ]}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <h2>Assista o vídeo para mais informações sobre o Dashboard</h2>

            <br />

            <iframe
              title="Dashboard"
              width="700"
              height="415"
              src="https://www.youtube.com/embed/IVkKajMrUxw"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Modal>
      </Container>
    </Layout>
  );
};

export default Dashboard;
