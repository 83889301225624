import styled from 'styled-components';

interface ContainerProps {
  lifeAreaColor?: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;

  padding-bottom: 20px;

  .title-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .lifearea-quadrant {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-right: 10px;
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      strong {
        font-size: 24px;
      }

      > h1 {
        color: ${props =>
          props.lifeAreaColor ? props.lifeAreaColor : undefined};
        margin-top: -10px;
      }
    }
  }

  .lifearea-quadrant {
    span {
      font-weight: bold;
      color: ${props =>
        props.lifeAreaColor ? props.lifeAreaColor : undefined};
      text-shadow: 1px 1px 2px black;
      font-size: 16px;

      & + span {
        margin-left: 10px;
      }
    }
  }

  .completed,
  .results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    display: flex;
    gap: 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto 0;

  p {
    flex-wrap: wrap;
    max-width: 80%;
  }

  .instructions {
    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
      font-size: 24px;
    }

    p {
      font-size: 20px;
    }
  }

  .benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .complete-area {
    margin-top: auto;
  }

  .completed {
    font-size: 18px;

    h3 {
      font-size: 24px;
    }

    .heart-frequency {
      display: flex;
      gap: 10px;
    }
  }
`;
