import React, { useState } from 'react';
import ApexChart from 'react-apexcharts';
import { Card, Button, Space, message, Row, Col, Spin } from 'antd';
import { Grid } from '@material-ui/core';

import { Container } from './styles';
import Layout from '../../components/Layout';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import Logo from '../../assets/images/reconectme_logo_positivo.png';
import { currencyFormat } from '../../utils/currency';

interface Props {
  // eslint-disable-next-line camelcase
  init_point: string;
}
interface IPlan {
  title: string;
  yourJourney: number;
  lifeArea: number;
  subTitle: string;
  value: number;
  selected: boolean;
}

const Payment: React.FC = () => {
  const { user } = useAuth();
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [dataPlan, setDataPlan] = useState<IPlan[]>([
    {
      title: 'Faça aqui sua jornada rápida!',
      yourJourney: 1,
      lifeArea: 11,
      subTitle: 'Equilibre 1 Área da vida por',
      value: 99.0,
      selected: false,
    },
    {
      title: 'Atinja 25% de equilíbrio!',
      yourJourney: 3,
      lifeArea: 9,
      subTitle: 'Equilibre 3 Áreas da vida por',
      value: 79.0,
      selected: false,
    },
    {
      title: 'Percorra 50% do caminho!',
      yourJourney: 6,
      lifeArea: 6,
      subTitle: 'Equilibre 6 Áreas da vida por',
      value: 69.0,
      selected: false,
    },
    {
      title: 'Bora fazer sua jornada completa!',
      yourJourney: 12,
      lifeArea: 0,
      subTitle: 'Equilibre 12 Áreas da vida por',
      value: 59.0,
      selected: false,
    },
  ]);

  const [dataResponse, setDataResponse] = useState<Props>({} as Props);

  async function success(data: IPlan, index: number): Promise<void> {
    setIsLoadingPayment(true);
    const dataPlanArray = dataPlan.map((item: IPlan, i: number) => {
      return {
        ...item,
        selected: index === i,
      };
    });
    setDataPlan(dataPlanArray);

    try {
      const response = await api.post('/create-payment', {
        email: user.email,
        days: 28 * data.yourJourney,
        value: data.value * data.yourJourney,
      });

      setDataResponse(response.data.response);
      message.success(`Compre ${data.yourJourney} mês de acesso ilimitado`, 3);
    } catch (err) {
      message.error('Erro ao realizar a assinatura. Tente novamente');
    }
    setIsLoadingPayment(false);
  }

  return (
    <Layout title="Planos de Assinatura">
      <Container>
        <div className="title-container">
          <h2>
            Agora que você já deu seus primeiros passos, continue na
            <img src={Logo} alt="reconect.me" />, pois o reequilibrio das áreas
            da vida são insubstituíveis
          </h2>
          {isLoadingPayment && <Spin size="large" />}
          {dataResponse.init_point && !isLoadingPayment && (
            <Button
              className="payment_btn"
              type="primary"
              style={{
                borderRadius: 25,
                fontWeight: 'bold',
                fontSize: 20,
                width: '250px',
                height: '50px',
              }}
            >
              <a
                href={dataResponse.init_point}
                target="_blank"
                rel="noreferrer"
              >
                Realizar Pagamento
              </a>
            </Button>
          )}
        </div>
        <Grid container spacing={2} alignItems="center" justify="center">
          {dataPlan.map((item: IPlan, index: number) => (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
              <PlanCard success={success} data={item} index={index} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Layout>
  );
};

const PlanCard: React.FC<any> = props => {
  const { success, data, index } = props;
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        title={data.title}
        headStyle={{
          color: '#7E1280',
          fontSize: '20px',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        onClick={() => success(data, index)}
        hoverable
        style={{
          width: '335px',
          borderRadius: 20,
          border: data.selected ? '2px solid #1A73E8' : '',
        }}
        cover={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <ApexChart
            type="pie"
            series={[data.yourJourney, data.lifeArea]}
            options={{
              colors: ['#F2A71B', '#711073'],
              chart: {
                type: 'pie',
              },
              labels: ['Sua jornada', 'Áreas da vida'],
              fill: {
                colors: ['#F2A71B', '#711073'],
              },
              dataLabels: {
                enabled: false,
              },
              legend: {
                onItemClick: {
                  toggleDataSeries: true,
                },
                onItemHover: {
                  highlightDataSeries: true,
                },
              },
              responsive: [
                {
                  breakpoint: 220,
                  options: {
                    chart: {
                      width: 200,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
              ],
            }}
            height={335}
            width={335}
          />
        }
      >
        <div className="spacePrice1">
          <Space direction="vertical">
            <h3>
              <div className="h3_TitlePrice">{data.subTitle}</div>
              <div className="h3_Price">{currencyFormat(data.value)}</div>
            </h3>
          </Space>
        </div>
      </Card>
    </div>
  );
};

export default Payment;
