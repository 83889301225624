import { Button, Card, Input, message, Modal, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { MdCheck } from 'react-icons/md';
import Layout from '../../components/Layout';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import { Container, QuadrantArea } from './styles';

interface IQuadrant {
  _id: string;
  name: string;
  color: string;
  description?: string;
  lifeAreas: string[];
}

interface ILifeArea {
  _id: string;
  name: string;
  color: string;
  description: string;
  initial?: number;
  desired?: number;
  current?: number;
}

interface ILifeAreaState {
  lifeArea: string;
  initial?: number;
  desired?: number;
  current?: number;
}

const LifeSheet: React.FC = () => {
  const [quadrants, setQuadrants] = useState<IQuadrant[]>([]);
  const [lifeAreas, setLifeAreas] = useState<ILifeArea[]>([]);
  const [lifeAreasStates, setLifeAreasStates] = useState<ILifeAreaState[]>([]);
  const [statesList, setStatesList] = useState<ILifeArea[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [didComplete, setDidComplete] = useState(false);
  const [currentDescription, setCurrentDescription] = useState('');
  const [loading, setLoading] = useState(true);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        const response = await api.get(`lifeAreas-quadrants/${user._id}/all`);

        setQuadrants(response.data.quadrants);
        setLifeAreas(response.data.lifeAreas);
        setLifeAreasStates(response.data.areaStates);
        if (response.data.areaStates.length > 0) setDidComplete(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [user._id]);

  const handleUpdateLifeAreasStates = useCallback(
    (type: 'initial' | 'desired', index: number, value: string) => {
      const lifeAreaUpdated = lifeAreas[index] as ILifeArea;

      lifeAreaUpdated[type] = Number(value);

      const lifeAreasStatesUpdated = lifeAreas.map(lifeArea => {
        if (lifeArea._id === lifeAreaUpdated._id) {
          return {
            ...lifeAreaUpdated,
          };
        }

        return lifeArea;
      });

      setStatesList(lifeAreasStatesUpdated);
    },
    [lifeAreas],
  );

  const handleCreateJourney = useCallback(async () => {
    if (statesList.length < lifeAreas.length) {
      message.warn('Preencha todas as áreas');
      return;
    }

    const areaStates = statesList.map(lifeAreaState => ({
      lifeArea: lifeAreaState._id,
      desired: lifeAreaState.desired,
      initial: lifeAreaState.initial,
      current: lifeAreaState.initial,
    }));

    try {
      await api.post(`journeys/${user._id}`, {
        areaStates,
      });

      setDidComplete(true);
    } catch (error) {
      message.error('Erro ao atualizar dados');
    }
  }, [lifeAreas.length, statesList, user._id]);

  if (loading) {
    return (
      <Layout title="Avaliação Inicial">
        <Spin size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Avaliação Inicial">
      <Container>
        <div className="info-area">
          {didComplete ? (
            <div className="title">
              <h3>Você já completou sua Avaliação Inicial!</h3>
              <Button
                type="primary"
                style={{ borderRadius: 20, fontWeight: 'bold' }}
                onClick={() => setIsVideoModalVisible(true)}
              >
                Ver mais
              </Button>
            </div>
          ) : (
            <>
              <div className="title">
                <h2>Preencha as informações da sua Avaliação Inicial</h2>
                <Button
                  type="primary"
                  style={{ borderRadius: 20, fontWeight: 'bold' }}
                  onClick={() => setIsVideoModalVisible(true)}
                >
                  Ver como
                </Button>
              </div>
              <div id="confirm-area">
                <Button
                  icon={<MdCheck />}
                  type="primary"
                  onClick={handleCreateJourney}
                >
                  Confirmar
                </Button>
              </div>
            </>
          )}
        </div>

        {quadrants.map(quadrant => (
          <QuadrantArea key={quadrant._id}>
            <div
              className="quadrant-card"
              style={{
                background: quadrant.color,
                minWidth: 200,
              }}
            >
              {quadrant.name}
            </div>

            {quadrant.lifeAreas.map(lifeArea => {
              const lifeAreaIndex = lifeAreas.findIndex(
                la => String(la._id) === String(lifeArea),
              );

              const areaStateIndex = lifeAreasStates.findIndex(
                areaState => String(areaState.lifeArea) === String(lifeArea),
              );

              let areaState = {} as ILifeAreaState;

              if (areaStateIndex >= 0) {
                areaState = lifeAreasStates[areaStateIndex];
              }

              if (lifeAreaIndex < 0) return <></>;

              const lifeAreaObj = lifeAreas[lifeAreaIndex];

              return (
                <Card
                  className="life-area-card"
                  key={lifeAreaObj._id}
                  title={lifeAreaObj.name}
                  headStyle={{ backgroundColor: lifeAreaObj.color }}
                  // eslint-disable-next-line prettier/prettier
                  extra={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Button
                      style={{ borderRadius: 20 }}
                      onClick={() => {
                        setCurrentDescription(lifeAreaObj.description);
                        setIsModalVisible(true);
                      }}
                    >
                      Mais
                    </Button>
                  }
                  style={{
                    minWidth: 200,
                    border: `1px solid ${lifeAreaObj.color}`,
                  }}
                >
                  <div className="inputs-group">
                    <div className="input-group">
                      <h4>Inicial</h4>
                      <Input
                        value={didComplete ? areaState.initial : undefined}
                        onChange={e => {
                          handleUpdateLifeAreasStates(
                            'initial',
                            lifeAreaIndex,
                            e.target.value,
                          );
                        }}
                        max={10}
                        min={0}
                        type="number"
                        required
                        disabled={didComplete}
                      />
                    </div>
                    <div className="input-group">
                      <h4>Atual</h4>
                      <Input
                        value={didComplete ? areaState.current : undefined}
                        type="number"
                        disabled
                      />
                    </div>
                    <div className="input-group">
                      <h4>Desejado</h4>
                      <Input
                        value={didComplete ? areaState.desired : undefined}
                        onChange={e => {
                          handleUpdateLifeAreasStates(
                            'desired',
                            lifeAreaIndex,
                            e.target.value,
                          );
                        }}
                        max={10}
                        min={0}
                        type="number"
                        required
                        disabled={didComplete}
                      />
                    </div>
                  </div>
                </Card>
              );
            })}
          </QuadrantArea>
        ))}

        <Modal
          title="Instruções"
          centered
          visible={isVideoModalVisible}
          onCancel={() => setIsVideoModalVisible(false)}
          onOk={() => setIsVideoModalVisible(false)}
          closable
          width={750}
          footer={[
            <Button
              type="primary"
              onClick={() => setIsVideoModalVisible(false)}
            >
              Ok
            </Button>,
          ]}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <h2>
              Assista o vídeo para mais informações sobre a Avaliação Inicial
            </h2>

            <br />

            <iframe
              title="Avaliação Inicial"
              width="700"
              height="415"
              src="https://www.youtube.com/embed/mFbkve-fBy0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Modal>

        <Modal
          title="Detalhes"
          centered
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onOk={() => setIsModalVisible(false)}
          closable
          footer={[
            <Button type="primary" onClick={() => setIsModalVisible(false)}>
              Ok
            </Button>,
          ]}
        >
          {currentDescription}
        </Modal>
      </Container>
    </Layout>
  );
};

export default LifeSheet;
