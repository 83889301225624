import { Button, Divider, Input, message, Modal, Spin } from 'antd';
import TextArea, { TextAreaRef } from 'antd/lib/input/TextArea';
import axios from 'axios';
import { format, isAfter } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { getYouTubeId } from '../../utils/getVideoID';

import { Container, Content } from './styles';
import configHeader from '../../services/headers';

interface IChallenge {
  _id: string;
  name: string;
  description?: string;
  benefits?: string;

  quadrant: {
    name: string;
    color: string;
  };
  lifeArea: {
    _id: string;
    name: string;
    color: string;
  };
  video?: {
    title: string;
    videoUrl: string;
  };

  level: 'easy' | 'medium' | 'hard';
}

interface ICompleteChallenge {
  result: string;
  initialHeartRate: number;
  finalHeartRate: number;

  createdAt?: Date;
}

const Challenges: React.FC = () => {
  const [dayChallenge, setDayChallenge] = useState<IChallenge>(
    {} as IChallenge,
  );
  const [
    completeChallenge,
    setCompleteChallenge,
  ] = useState<ICompleteChallenge>({} as ICompleteChallenge);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isStartModalVisible, setIsStartModalVisible] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCompleteModalVisible, setIsCompleteModalVisible] = useState(false);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [canViewChallenge, setCanViewChallenge] = useState(false);

  const initialHeartRateRef = useRef<Input>(null);
  const finalHeartRateRef = useRef<Input>(null);
  const resultRef = useRef<TextAreaRef>(null);

  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        const response = await api.get(`challenges/${user._id}/day`);
        const completeResponse = await api.get(
          `challenges/${user._id}/did-complete`,
        );

        const { complete, completedChallenge } = completeResponse.data;

        setIsBlocked(complete);
        setDayChallenge(response.data);

        if (complete) {
          setIsStartModalVisible(false);
          setCompleteChallenge(completedChallenge);
          setCompleted(complete);
          setCanViewChallenge(true);
        }

        if (new Date().getHours() >= 22) {
          setIsBlocked(true);
        }
      } catch (error) {
        setDayChallenge({} as IChallenge);
        setIsStartModalVisible(false);
      } finally {
        setLoading(false);
      }
    }

    loadData();

    // loadPaymentDataUser();
  }, [user._id]);

  async function loadPaymentDataUser(): Promise<void> {
    const response = await api.post('/get-payment-data-user', {
      email: user.email,
    });

    try {
      if (response.data.payment_id) {
        const responseMP = await axios.get(
          `https://api.mercadopago.com/v1/payments/${response.data.payment_id}`,
          configHeader,
        );

        if (
          responseMP.data.status !== 'approved' ||
          isAfter(new Date(), new Date(response.data.next_payment_data))
        ) {
          history.push('/payment');
        }
      } else {
        history.push('/finish-journey');
      }
    } catch (err) {
      message.error(err);
    }
  }

  const dateFormatted = useMemo(() => {
    const date = format(new Date(), 'dd/MM/yyyy');

    return date;
  }, []);

  const handleStartChallenge = (): void => {
    if (!initialHeartRateRef.current?.input.value) {
      message.warn('Preecha a frequência cardíaca para iniciar!');
      return;
    }

    setCanViewChallenge(true);
    setIsStartModalVisible(false);
  };

  const handleCompleteChallenge = async (): Promise<void> => {
    if (
      !finalHeartRateRef.current?.input.value ||
      !resultRef.current?.resizableTextArea?.textArea.value ||
      !initialHeartRateRef.current?.input.value
    ) {
      message.warn('Preecha a frequência cardíaca e o resultado para concluir');
      return;
    }

    try {
      await api.put(`challenges/${user._id}/complete`, {
        challengeId: dayChallenge._id,
        challengeArea: dayChallenge.lifeArea._id,
        result: resultRef.current.resizableTextArea.textArea.value,
        initialHeartRate: initialHeartRateRef.current.input.value,
        finalHeartRate: finalHeartRateRef.current.input.value,
      });

      const completeResponse = await api.get(
        `challenges/${user._id}/did-complete`,
      );

      const { complete, completedChallenge } = completeResponse.data;

      setIsBlocked(complete);

      if (complete) {
        setIsStartModalVisible(false);
        setCompleteChallenge(completedChallenge);
        setCompleted(complete);
      }
    } catch (error) {
      message.error('Erro ao completar atividade');
    } finally {
      setIsCompleteModalVisible(false);
    }

    // .catch(() => message.error('Erro ao completar desafio'))
    // .finally(() => );
  };

  const isYouTubeUrl = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|&v=|^https:\/\/www\.youtube\.com\/watch\?v=)([^#&?]*).*/;
    return regExp.test(url);
  };

  if (loading) {
    return (
      <Layout title="Atividades">
        <Spin size="large" />
      </Layout>
    );
  }

  if (!dayChallenge.name) {
    return (
      <Layout title="Atividades">
        <Container>
          <h1>Parece que você não tem atividades para hoje</h1>
        </Container>
      </Layout>
    );
  }

  return (
    <>
      <Layout title="Atividades">
        <Container lifeAreaColor={dayChallenge.lifeArea.color}>
          {dateFormatted}
          {canViewChallenge && (
            <>
              <div className="title-area">
                <Button
                  type="primary"
                  style={{ borderRadius: 20, fontWeight: 'bold' }}
                  onClick={() => setIsVideoModalVisible(true)}
                >
                  Ver instruções
                </Button>

                <div className="title">
                  <strong>Atividade de hoje</strong>
                  <h1>{dayChallenge.name}</h1>
                </div>

                <div className="lifearea-quadrant">
                  <span>{dayChallenge.quadrant?.name}</span>
                  <span>{dayChallenge.lifeArea?.name}</span>
                </div>
              </div>

              <Content>
                <div className="instructions">
                  <strong>Instruções:</strong>
                  <p>{dayChallenge.description}</p>
                </div>

                {dayChallenge.video && (
                  <div className="video">
                    {isYouTubeUrl(dayChallenge.video.videoUrl) ? (
                      <iframe
                        title="Video"
                        width="500"
                        height="280"
                        src={`https://www.youtube.com/embed/${getYouTubeId(
                          dayChallenge.video.videoUrl,
                        )}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    ) : (
                      <a
                        href={dayChallenge.video.videoUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {dayChallenge.video.videoUrl}
                      </a>
                    )}
                  </div>
                )}

                <Button onClick={() => setIsModalVisible(true)}>
                  Ver Benefícios da atividade
                </Button>

                {!completed && (
                  <>
                    <Divider />

                    <div className="complete-area">
                      <Button
                        size="large"
                        type="primary"
                        onClick={() => setIsCompleteModalVisible(true)}
                        disabled={isBlocked}
                      >
                        {isBlocked
                          ? 'Você não pode mais completar a atividade'
                          : 'COMPLETAR ATIVIDADE'}
                      </Button>
                    </div>
                  </>
                )}

                <Divider />

                {completed && (
                  <div className="completed">
                    <h3>Variações de suas emoções:</h3>
                    <div className="heart-frequency">
                      <span>
                        <strong>FCi:</strong>{' '}
                        {completeChallenge.initialHeartRate}
                      </span>
                      <span>
                        <strong>FCf:</strong> {completeChallenge.finalHeartRate}
                      </span>
                      <span>
                        <strong>Sua FC foi alterada em: </strong>
                        {completeChallenge.finalHeartRate -
                          completeChallenge.initialHeartRate}
                        bpm
                      </span>
                    </div>
                    <div className="results">
                      <h3>Resultado:</h3>
                      <p>{completeChallenge.result}</p>
                    </div>
                  </div>
                )}
              </Content>
            </>
          )}

          <Modal
            title="Instruções"
            centered
            visible={isVideoModalVisible}
            onCancel={() => setIsVideoModalVisible(false)}
            onOk={() => setIsVideoModalVisible(false)}
            closable
            width={750}
            footer={[
              <Button
                type="primary"
                onClick={() => setIsVideoModalVisible(false)}
              >
                Ok
              </Button>,
            ]}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <h2>Assista o vídeo para mais informações sobre a atividade</h2>

              <br />

              <iframe
                title="Avaliação Inicial"
                width="700"
                height="415"
                src="https://www.youtube.com/embed/QqL-jdeCS4E"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </Modal>

          <Modal
            title="Iniciar atividade"
            centered
            visible={isStartModalVisible}
            closable={false}
            footer={[
              <Button
                onClick={() => {
                  setIsStartModalVisible(false);
                  history.goBack();
                }}
              >
                Voltar
              </Button>,
              <Button type="primary" onClick={handleStartChallenge}>
                Iniciar
              </Button>,
            ]}
          >
            <div className="complete-form">
              <div className="input-group">
                <h4>Frequência cardíaca Inicial</h4>
                <Input ref={initialHeartRateRef} type="number" />
              </div>
            </div>
          </Modal>

          <Modal
            title="Instruções"
            centered
            visible={isVideoModalVisible}
            onCancel={() => setIsVideoModalVisible(false)}
            onOk={() => setIsVideoModalVisible(false)}
            closable
            width={750}
            footer={[
              <Button
                type="primary"
                onClick={() => setIsVideoModalVisible(false)}
              >
                Ok
              </Button>,
            ]}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <h2>Assista o vídeo para mais informações sobre a atividade</h2>

              <br />

              <iframe
                title="Atividades"
                width="700"
                height="415"
                src="https://www.youtube.com/embed/QqL-jdeCS4E"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </Modal>

          <Modal
            title="Benefícios da atividade"
            centered
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onOk={() => setIsModalVisible(false)}
            closable
            footer={[
              <Button type="primary" onClick={() => setIsModalVisible(false)}>
                Ok
              </Button>,
            ]}
          >
            <div className="benefits">
              <p>{dayChallenge.benefits}</p>
            </div>
          </Modal>

          <Modal
            title="Completar atividade"
            centered
            visible={isCompleteModalVisible}
            onCancel={() => setIsCompleteModalVisible(false)}
            onOk={() => setIsCompleteModalVisible(false)}
            closable
            footer={[
              <Button onClick={() => setIsCompleteModalVisible(false)}>
                Cancelar
              </Button>,
              <Button type="primary" onClick={handleCompleteChallenge}>
                COMPLETAR
              </Button>,
            ]}
          >
            <div className="complete-form">
              <div className="input-group">
                <h4>Frequência cardíaca final</h4>
                <Input ref={finalHeartRateRef} type="number" />
              </div>
              <div className="input-group">
                <h4>Resultado da atividade</h4>
                <TextArea ref={resultRef} cols={3} />
              </div>
            </div>
          </Modal>
        </Container>
      </Layout>
    </>
  );
};

export default Challenges;
