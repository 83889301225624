import { useState } from 'react';
import Layout from '../../components/Layout';

import { Container } from './styles';

const Guide: React.FC = () => {
  return (
    <Layout title="Guide">
      <Container>
        <div className="title">
          <h2>Vídeo de Guia</h2>

          <iframe
            title="Dashboard"
            width="720"
            height="435"
            src="https://www.youtube.com/embed/2nzAJTnLEqM"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Container>
    </Layout>
  );
};

export default Guide;
