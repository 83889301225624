import React, { useEffect } from 'react';
import api from '../../services/api';

const CreatePayment: React.FC = () => {
  useEffect(() => {
    createPayment();
  }, []);

  async function createPayment(): Promise<void> {
    const path = window.location.pathname.split('/');
    const query = window.location.search.split('?')[1].split('&');

    await api.get(
      process.env.REACT_APP_ENVIRONMENT === 'development'
        ? `http://localhost:3333/get-payment-feedback/${path[2]}/${path[3]}/${
            query[3].split('=')[1]
          }/${query[2].split('=')[1]}`
        : `${process.env.REACT_APP_API_URL}/get-payment-feedback/${path[2]}/${
            path[3]
          }/${query[3].split('=')[1]}/${query[2].split('=')[1]}`,
    );
  }

  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <a
        style={{
          background: '#6b0a95',
          color: '#FFF',
          borderRadius: '10px',
          padding: '10px',
          textDecoration: 'none',
        }}
        href="https://app.reconect.me/"
      >
        Voltar para APP Reconectme
      </a>
    </div>
  );
};

export default CreatePayment;
